import { createContext, useEffect, useReducer, useState } from "react";
import { useAuthContext } from "../Hooks/useAuthContext";
import useRequest from "../hooks/useRequest";

export const UserContext = createContext();

export const authReducer = (state, action) => {
    return { user: action.payload, loading: action.loading };
};

export const UserContextProvider = ({ children }) => {
    const { error, loading, requestHandler } = useRequest();
    const [user, setUser] = useState(null);
    const [currentLoading, setCurrentLoading] = useState(false);
    const { user: userCont } = useAuthContext();
    const fetchCurrentUser = () => {
        let url = "";
        switch (localStorage.getItem("role")) {
            case "admin":
                url = "/admin";
                break;
            case "agent":
                url = "/agent";
                break;
            default:
                url = "/client";
                break;
        }
        setCurrentLoading(true);
        requestHandler(
            {
                url: process.env.REACT_APP_BACKEND_URL + url,
                method: "GET",
                header: { Authorization: `Bearer ${userCont}` },
            },
            setUser
        );
        setCurrentLoading(false);
    };

    useEffect(() => {
        if (userCont !== null) {
            fetchCurrentUser();
        }
    }, [userCont]);

    const [state, dispatch] = useReducer(authReducer, {
        user: null,
    });

    useEffect(() => {
        if (user?.error?.reason === "UNAUTHORIZED") {
            localStorage.removeItem("user");
        }
        if (
            user?.unverified_email !== null &&
            typeof user?.unverified_email === "string"
        ) {
            window.location.href = "/c/dashboard/profile";
        }

        dispatch({ payload: user?.client, loading: currentLoading });
    }, [user]);

    return (
        <UserContext.Provider value={{ ...state, dispatch }}>
            {children}
        </UserContext.Provider>
    );
};
