import {
    CssBaseline,
    StyledEngineProvider,
    ThemeProvider,
} from "@mui/material";
import React, { Fragment, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import { useUserContext } from "./Hooks/useUserContext";
import Pace from "./shared/components/Pace";
import theme from "./theme";
import ScrollToTop from "./logged_out/components/ScrollTop";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));
const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));
const AdminLoggedOutComponent = lazy(() =>
    import("./logged_out/components/AdminMain")
);
const AdminLoggedInComponent = lazy(() =>
    import("./logged_in/components/AdminMain")
);
const AgentLoggedOutComponent = lazy(() =>
    import("./logged_out/components/AgentMain")
);
const AgentLoggedInComponent = lazy(() =>
    import("./logged_in/components/AgentMain")
);

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles />
                    <Pace color={theme.palette.primary.light} />
                    <Suspense fallback={<Fragment />}>
                        <Switch>
                            <Route path="/c/dashboard">
                                <LoggedInComponent />
                            </Route>
                            <Route path="/a/dashboard">
                                {/* {currentUser === undefined &&
                                !isLoggedIn(currentUser) ? (
                                    <AdminLoginPage />
                                ) : (
                                    <AdminLoggedInComponent />
                                )} */}
                                {<AdminLoggedInComponent />}
                            </Route>
                            <Route path="/s/dashboard">
                                {/* {currentUser === undefined &&
                                !isLoggedIn(currentUser) ? (
                                    <AgentloginPage />
                                ) : (
                                    <AgentLoggedInComponent />
                                )} */}
                                {<AgentLoggedInComponent />}
                            </Route>
                            <Route>
                                {localStorage.getItem("role") === "admin" ? (
                                    <AdminLoggedOutComponent />
                                ) : localStorage.getItem("role") === "agent" ? (
                                    <AgentLoggedOutComponent />
                                ) : (
                                    <LoggedOutComponent />
                                )}
                            </Route>
                        </Switch>
                    </Suspense>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    );
}

export default App;
