import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import translationEN from './locales/english.json';
import translationKA from './locales/georgian.json';
import translationRU from './locales/russian.json';

// the translations
const resources = {
    english: {
        translation: translationEN
    },
    georgian: {
        translation: translationKA
    },
    russian: {
        translation: translationRU
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'georgian', // use en if detected lng is not available
        lng: localStorage.getItem('i18nextLng') || 'georgian',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;