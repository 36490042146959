import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material";

// colors
const primary = "#3587F2";
const secondary = "#424769";
const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const background = "#F3F6F9";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(255, 128, 43, 1)";
const warningDark = "rgba(253, 200, 69, .7)";
const white = "#fff";
const green = "#9ADE7B";
const darkGreen = "#27790e";
const purpleLight = "#E9A8F2";
const purpleMain = "#DD58D6";
const purpleDark = "#9336B4";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints 864AF9 
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;
const ss = 350;

// spacing
const spacing = 8;

const theme = createTheme(
    adaptV4Theme({
        palette: {
            primary: { main: primary },
            green: { main: green, dark: darkGreen },
            secondary: { main: secondary },
            black: { main: black },
            common: {
                black,
                darkBlack,
            },
            purple: {
                light: purpleLight,
                main: purpleMain,
                dark: purpleDark,
            },
            warning: {
                light: warningLight,
                main: warningMain,
                dark: warningDark,
            },
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            background: {
                default: background,
                white: white
            },
            spacing,
        },
        breakpoints: {
            // Define custom breakpoint values.
            // These will apply to Material-UI components that use responsive
            // breakpoints, such as `Grid` and `Hidden`. You can also use the
            // theme breakpoint functions `up`, `down`, and `between` to create
            // media queries for these breakpoints
            values: {
                xl,
                lg,
                md,
                sm,
                xs,
                ss,
            },
        },
        border: {
            borderColor: borderColor,
            borderWidth: borderWidth,
        },
        // vars: {
        //     fontFamily: "",
        //     radius: "",
        //     fontSize: "",
        //     palette: {
        //         text: "",
        //         background: {
        //             default: background,
        //         },
        //         primary: { main: primary },
        //         secondary: { main: secondary },
        //     },
        // },

        focus: "",
        variants: {},
        overrides: {
            MuiExpansionPanel: {
                root: {
                    position: "static",
                },
            },
            MuiTableCell: {
                root: {
                    paddingLeft: spacing * 2,
                    paddingRight: spacing * 2,
                    borderBottom: `${borderWidth}px solid ${borderColor}`,
                    [`@media (max-width:  ${sm}px)`]: {
                        paddingLeft: spacing,
                        paddingRight: spacing,
                    },
                },
            },
            MuiDivider: {
                root: {
                    backgroundColor: borderColor,
                    height: borderWidth,
                },
            },
            MuiPrivateNotchedOutline: {
                root: {
                    borderWidth: borderWidth,
                },
            },
            MuiListItem: {
                divider: {
                    borderBottom: `${borderWidth}px solid ${borderColor}`,
                },
            },
            MuiDialog: {
                paper: {
                    width: "100%",
                    maxWidth: 430,
                    marginLeft: spacing,
                    marginRight: spacing,
                    [`@media (max-width:  ${md}px)`]: {
                        maxWidth: "100%",
                    },
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: darkBlack,
                },
            },
            MuiExpansionPanelDetails: {
                root: {
                    [`@media (max-width:  ${sm}px)`]: {
                        paddingLeft: spacing,
                        paddingRight: spacing,
                    },
                },
            },
        },
        typography: {
            useNextVariants: true,
        },
    })
);

export default responsiveFontSizes(theme);
