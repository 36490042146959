import { useState, useCallback } from "react";

const useRequest = () => {
    const [error, setError] = useState(null);
    const [loading, setIsLoading] = useState(false);

    const requestHandler = useCallback(async (configData, applyData) => {
        setIsLoading(true);
        setError(null);
        // try {
        const response = await fetch(configData.url, {
            method: configData.method,
            body: configData.body ? JSON.stringify(configData.body) : null,
            headers: configData.header,
        }).then((response) => {
            if (response.status >= 400 && response.status < 600) {
                if (
                    response.status === 401 &&
                    window.location.pathname.includes("c/dashboard")
                ) {
                    window.location.href = "/c/login";
                    return;
                }
            }
            return response.json();
        })
            .then((returnedResponse) => {
                applyData(returnedResponse);
            })
            .catch(function (error) {
                applyData(error);
            });
        setIsLoading(false);

        // if (response.status >= 400 && response.status < 600) {
        //     const errRes = await response.json();
        //     applyData(errRes);
        //     return;
        // }

        // const result = await response.json();

        // if (
        //     result?.error?.reason === "UNAUTHORIZED" &&
        //     window.location.pathname.includes("c/dashboard")
        // ) {
        //     window.location.href = "/c/login";
        //     return;
        // }
        // applyData(result);
        // } catch (error) {
        //     setError(error);
        // }
    }, []);

    return {
        error: error,
        loading: loading,
        requestHandler: requestHandler,
    };
};

export default useRequest;
