import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { SnackbarProvider } from "notistack";
import { AuthContextProvider } from "./Context/AuthContext";
import { UserContextProvider } from "./Context/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./i18n";

ReactDOM.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AuthContextProvider>
            <UserContextProvider>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <App />
                </SnackbarProvider>
            </UserContextProvider>
        </AuthContextProvider>
    </GoogleOAuthProvider>,

    document.getElementById("root")
);

serviceWorkerRegistration.unregister();
